var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"}),_c('l-table',{ref:"lTableRef",attrs:{"table-config-options":{
      sortBy:'created_at',
      searchKey: 'key',
    },"module-name":_vm.brandModuleName,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-catalog-brand-create' },"trash-list-path":"settings-catalog-brand-trash-list","can-create":{action: 'change', subject: 'Defaults'},"can-show-trash-list":{action: 'change', subject: 'Defaults'}},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"text-nowrap",staticStyle:{"cursor":"default"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.top",modifiers:{"noninteractive":true,"hover":true,"top":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":_vm.$t('Set Inactive'),"icon":"LTrashIconKits","size":"32"},on:{"click":function($event){return _vm.setInActive(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.top",modifiers:{"noninteractive":true,"hover":true,"top":true}}],staticClass:"cursor-pointer",attrs:{"title":_vm.$t('Update'),"icon":"LPenIconUpdated","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-catalog-brand-update', params: { id: data.item.id } }, $event)}}})],1)]}},{key:"cell(name)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-catalog-brand-information', params: {id: item.id} }, $event)}}},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'name', '—'))+" ")])]}},{key:"cell(assets)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"d-block text-center"},[_c('b-link',{staticStyle:{"color":"#646464"},attrs:{"to":{ name: 'settings-brands-products-list' , params: { id: item.id }}}},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'products_count', '—'))+" ")])],1)]}},{key:"cell(id)",fn:function(ref){
    var item = ref.data.item;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'id', '—'))+" ")])]}},{key:"cell(is_active)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getStatus(data.value).name)+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }