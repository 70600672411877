<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <!--      {{ $t('Brand List') }}-->
    </h4>
    <l-table
      ref="lTableRef"
      :table-config-options="{
        sortBy:'created_at',
        searchKey: 'key',
      }"
      :module-name="brandModuleName"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-catalog-brand-create' }"
      trash-list-path="settings-catalog-brand-trash-list"
      :can-create="{action: 'change', subject: 'Defaults'}"
      :can-show-trash-list="{action: 'change', subject: 'Defaults'}"
    >
      <template
        #cell(actions)="{ data }"
      >
        <div
          class="text-nowrap"
          style="cursor: default"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.top
            :title="$t('Set Inactive')"
            icon="LTrashIconKits"
            size="32"
            class="mr-1 cursor-pointer"
            @click="setInActive(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.top
            :title="$t('Update')"
            icon="LPenIconUpdated"
            size="32"
            class="cursor-pointer"
            @click="gotoNewPage({ name: 'settings-catalog-brand-update', params: { id: data.item.id } }, $event)"
          />
        </div>
      </template>
      <template #cell(name)="{data: {item}}">
        <div
          style="cursor: pointer"
          @click="gotoNewPage({ name: 'settings-catalog-brand-information', params: {id: item.id} }, $event)"
        >
          {{ getValueFromGivenObjectByKey(item, 'name', '—') }}
        </div>
      </template>
      <template #cell(assets)="{data: {item}}">
        <div class="d-block text-center">
          <b-link
            style="color: #646464"
            :to="{ name: 'settings-brands-products-list' , params: { id: item.id }}"
          >
            {{ getValueFromGivenObjectByKey(item, 'products_count', '—') }}
          </b-link>
        </div>
      </template>
      <template
        #cell(id)="{data: {item}}"
      >
        <div class="text-center">
          {{ getValueFromGivenObjectByKey(item, 'id', '—') }}
        </div>
      </template>
      <template #cell(is_active)="{data}">
        {{ getStatus(data.value).name }}
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { BLink, VBTooltip } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import tableConfig from '../brandConfig'

export default {
  name: 'BrandList',
  components: {
    BLink,
    LTable,
  },
  inject: ['brandModuleName'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapGetters('listModule', ['getStatus']),
  },
  methods: {
    getValueFromGivenObjectByKey,
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      const { id } = data

      this.confirmNotification(this, [id], `${this.brandModuleName}/moveToTrash`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' }, false)
        .then(() => {
          this.refetchData()
        }).catch(err => {
          this.toastErrorNotification(this, err.response?.data.message, err.response.status)
        })
    },
  },
  setup() {
    const MODULE_NAME = 'brand'

    const { tableColumns } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
    }
  },
}
</script>
